import { GuidesSection } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Ankle } from '../components/ankle';
import { FaqSection } from '../components/faq';
import { Layout } from '../components/layout';
import { getPageSections } from '../modules/handle-sanity-data';
import { QueryData } from '../modules/sanity-data-types';

export const pageQuery = graphql`
  query {
    pages: allSanityPage(filter: { title: { eq: "FAQS" } }) {
      nodes {
        pageBuilder {
          name
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export interface PageProps {
  data: QueryData;
}

export const Faq = ({ data }: PageProps) => {
  const pageSections: Record<string, any> = getPageSections(data);

  return (
    <Layout>
      {pageSections?.section1 && <FaqSection faqData={pageSections?.section1} />}
      {pageSections?.section2 && <Ankle Ankledata={pageSections?.section2} />}
      <GuidesSection />
    </Layout>
  );
};
export default Faq;
